import React from 'react';
import PropTypes from 'prop-types';
import { rules } from '@sb1/designsystem-uustatus';
import { LinkText } from '@sb1/ffe-core-react';

const TestCaseListItem = ({ testCase }) => {
    const ruleId = testCase.ruleId ? testCase.ruleId : testCase;
    const wcaginfo = rules.find(rule => rule.id === ruleId);

    return (
        <li key={ruleId} className="sb1ds-uutest-report__list-item">
            {wcaginfo ? <LinkText href={wcaginfo.descriptionUrl}>{wcaginfo.section} {wcaginfo.name}</LinkText> : <>Fant ikke kravbeskrivelse for {ruleId}</>}
        </li>
    );
};

TestCaseListItem.propTypes = {
    testCase: PropTypes.object
};
export default TestCaseListItem;