import React, { useState } from 'react';
import { Accordion, AccordionItem } from '@sb1/ffe-accordion-react';
import { InlineExpandButton } from '@sb1/ffe-buttons-react';
import { LinkText } from '@sb1/ffe-core-react';
import testData from '@sb1/designsystem-uustatus';
import PropTypes from 'prop-types';
import TestCaseList from './TestCaseList';
const UUTestReport = ({ reportID }) => {
    const report = testData.reports.find(report => report.id == reportID);
    if (!report) {
        return <p><strong>No report found</strong> with id: {reportID}</p>;
    }
    const [isOpen, setIsOpen] = useState(false);
    const sortOnStatus = status => report.testCases.filter(testCase => testCase.status === status);
    const notTested = sortOnStatus('ikke_testet');
    const approved = sortOnStatus('godkjent');
    const notApproved = sortOnStatus('ikke_godkjent');
    const githubURL = 'https://github.com/sparebank1utvikling/designsystem-uustatus/tree/main/reports/' + reportID.replace('_', '/');
    return (
        <>
            <div className="sb1ds-uutest-report__inline-expand-button">
                <InlineExpandButton
                    aria-controls={`testReport-${reportID}`}
                    aria-expanded={isOpen}
                    isExpanded={isOpen}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    Universell utforming
                </InlineExpandButton>
            </div>
            {isOpen &&
                <div id={`testReport-${reportID}`} className="sb1ds-uutest-report">
                    <p>Her kan du se hvordan det står til med komponenten i forhold til de norske lovkravene til universell utforming.
                        <br /><br />
                        Godkjent betyr at vi ikke fant feil når vi testet komponenten for kravet, mens ikke godkjent betyr at vi fant feil. Disse dokumenteres som <LinkText href="https://github.com/SpareBank1/designsystem/issues">issues i designsystemet på GitHub</LinkText>. Vær obs på at det fortsatt kan finnes feil selv om de ikke dukket opp i testene.
                        <br /><br />
                        Vi tester også for mer enn bare lovkravene. Det vil bli synlig i oversikten etter hvert.
                    </p>
                    <Accordion headingLevel={2}>
                        {<AccordionItem heading={`Ikke godkjent (${notApproved.length})`}>
                            <TestCaseList items={notApproved} />
                        </AccordionItem>}
                        <AccordionItem heading={`Godkjent (${approved.length})`}>
                            <TestCaseList items={approved} />
                        </AccordionItem>
                        <AccordionItem heading={`Ikke testet (${notTested.length})`}>
                            <TestCaseList items={notTested} />
                        </AccordionItem>
                        <AccordionItem heading={`Ikke relevant (${report.ikkeRelevanteKrav.length})`}>
                            <TestCaseList items={report.ikkeRelevanteKrav} />
                        </AccordionItem>
                    </Accordion>
                    <LinkText className="sb1ds-uutest-report__link-text" href={githubURL}>Se dokumentasjonen av testene på GitHub</LinkText>
                </div>
            }
        </>
    );
};

UUTestReport.propTypes = {
    reportID: PropTypes.string.isRequired
};

export default UUTestReport;
