import React from 'react';
import { node, string } from 'prop-types';

export const IconsUseCaseListItem = ({ name, description, icon }) => {
    return (
        <div className="sb1ds-icons-use-case-list__row">
            <dt className="sb1ds-icons-use-case-list__name">{name}</dt>
            <dd className="sb1ds-icons-use-case-list__description">{description}</dd>
            <dd className="sb1ds-icons-use-case-list__icon" aria-hidden="true">
                {icon}
            </dd>
        </div>
    );
};

IconsUseCaseListItem.propTypes = {
    name: string.isRequired,
    description: string.isRequired,
    icon: node.isRequired
};

export const IconsUseCaseList = ({ children }) => {
    return (
        <dl className="sb1ds-icons-use-case-list">{children}</dl>
    );
};

IconsUseCaseList.propTypes = {
    children: node.isRequired
};
