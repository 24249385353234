import React from 'react';
import { object, node, string } from 'prop-types';
import { themes, Highlight } from 'prism-react-renderer';
import { Link } from 'gatsby';
import { packages } from '@sb1/ffe-component-index';

const TokenSpan = ({token, children, ...props}) => {
    if (token.types.includes('string')) {
        const str = token.content.slice(1,-1);
        const pkg = packages.find(p => p.name === str);

        if (pkg) {
            return (<span {...props}>
                    {'\''}<Link to={`/package/${pkg.name}`}>{pkg.name}</Link>{'\''}
                </span>);
        }
    }

    return <span {...props}>{children}</span>;
};

TokenSpan.propTypes = {
    token: object.isRequired,
    children: node.isRequired
};

const CodeImports = ({code}) => {
    return (
        <Highlight code={code} theme={themes.vsLight} language="jsx">
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <div className="sb1ds-component-preview__code-imports">
                    <pre className={className} style={{...style, padding: '10px', margin: '0'}}>
                    {tokens.map((line, i) => (
                        <div key={i} {...getLineProps({ line, key: i })}>
                        {line.map((token, key) => (
                            <TokenSpan key={key} {...getTokenProps({ token, key })} token={token}/>
                        ))}
                        </div>
                    ))}
                    </pre>
                </div>
            )}
        </Highlight>
    );
};

CodeImports.propTypes = {
    code: string.isRequired
};

export default CodeImports;
