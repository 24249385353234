import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live';
import { themes } from 'prism-react-renderer';
import didYouMean, { ReturnTypeEnums } from 'didyoumean2';
import { examples } from '@sb1/ffe-component-index';
import { InlineExpandButton } from '@sb1/ffe-buttons-react';
import CodeImports from './CodeImports';

const DSExample = ({ name, noPadding }) => {
    if (!examples[name]) {
        const suggestions = didYouMean(name, Object.keys(examples), { returnType: ReturnTypeEnums.ALL_CLOSEST_MATCHES });
        return <div className="sb1ds-component-preview sb1ds-component-preview--err404">
            <p><strong>No such example: <code>{name}</code></strong></p>
            {suggestions && <p>Did you mean: {suggestions.join(', ')}?</p>}
        </div>;
    }

    const example = examples[name];

    const [showSourceCode, setShowSourceCode] = useState(false);
    const toggleSourceCode = () => setShowSourceCode(showIt => !showIt);

    return (<LiveProvider code={example.code} scope={example.scope} theme={themes.vsLight}>
        <div className="sb1ds-component-preview">
            <LivePreview className={noPadding ? 'sb1ds-component-preview__live-preview sb1ds-component-preview__live-preview--no-padding' : 'sb1ds-component-preview__live-preview'} />

            <div className="sb1ds-component-preview__toggle-button-container">
                <InlineExpandButton
                    aria-controls={`codeExample-${name}`}
                    aria-expanded={showSourceCode}
                    isExpanded={showSourceCode}
                    onClick={toggleSourceCode}
                >
                    Kode
                </InlineExpandButton>
            </div>

            {showSourceCode && <div id={`codeExample-${name}`} className="sb1ds-component-preview__code-example">
                <CodeImports code={example.sourceImports} />
                <LiveEditor className="sb1ds-component-preview__live-editor" />
                <div className="sb1ds-component-preview__source-code-ref">
                    Kilde på github: <a href={`https://github.com/SpareBank1/designsystem/blob/develop/component-overview/examples/${example.sourceFileName}`}>{example.sourceFileName}</a>
                </div>
            </div>}
            <LiveError className="sb1ds-component-preview__live-error" />

        </div>
    </LiveProvider>);
};

DSExample.propTypes = {
    name: PropTypes.string.isRequired,
    noPadding: PropTypes.bool
};

export default DSExample;
