import React, { useState } from 'react';
import { arrayOf, oneOfType, node} from 'prop-types';

const AnimationExample = ({children}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    return (
        <div className={`sb1ds-svgcontainer ${isPlaying ? 'sb1ds-svgcontainer--playing' : 'sb1ds-svgcontainer--paused'}`} onClick={() => setIsPlaying(!isPlaying)} role="button" >
            <div>
                {children}
            </div>
        </div>
    );
};

AnimationExample.propTypes = {
    children: oneOfType([node, arrayOf(node)])
};

export default AnimationExample;