import React from 'react';
import {LinkText} from '@sb1/ffe-core-react';
import {func} from 'prop-types';
import {trackButtonClick} from '../util/trackButtonClick';

export const LinkWithTracking = ({onClick, ...rest}) => {
    const handleClick = (e) => {
        if (onClick) {
            onClick(e);
        }
        try {
            trackButtonClick({action: 'mdx-link', text: rest.children});
        } catch (e) {
            console.error(e);
        }
    };

    return <LinkText {...rest} onClick={handleClick}/>;
};

LinkWithTracking.propTypes = {
    onClick: func
};