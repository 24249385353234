import React from 'react';
import PropTypes from 'prop-types';
import TestCaseListItem from './TestCaseListItem';

const TestCaseList = ({ items }) => {
    return items.length > 0 ? (
        <ul className="sb1ds-uutest-report__list">
            {items.map(testCase => <TestCaseListItem key={testCase.ruleId} testCase={testCase} />)}
        </ul>
    ) : null;
};

TestCaseList.propTypes = {
    items: PropTypes.array
};
export default TestCaseList;