import { SkipNavContent, SkipNavLink } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import { node, string } from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import InPageNavigation from '../components/InPageNavigation';
import Navigation from '../components/Navigation';
import useSiteMetadata from '../util/useSiteMetadata';
/**
 * Import assets
 */
import favicon from '../../static/favicon.ico';
import '../css-imports';

/**
 * Common page layout
 *
 * Wraps a page with shared elements like navigation and sidebar, sets head
 * elements and provides an application context.
 */
const Layout = ({children, title, introtext, illustration}) => {
    const site = useSiteMetadata();

   return (
       <>
           <Helmet
               titleTemplate={`%s — ${site.title}`}
               defaultTitle={site.title}
           >
               <html lang="nb" className="regard-color-scheme-preference"/>
               <title>{title}</title>
               <link rel="shortcut icon" href={favicon}></link>
               <meta name="theme-color" content="#002776"/>
               <meta name="color-scheme" content="light dark"/>
           </Helmet>
           <SkipNavLink>Hopp til innhold</SkipNavLink>
           <div className="sb1ds-wave regard-color-scheme-preference">
               <div className="sb1ds-layout">
                   <Navigation/>
                   <main className='ffe-body-text sb1ds-main'>
                        <SkipNavContent />
                        <div className="sb1ds-intro">
                           <h1 className="ffe-h1 sb1ds-intro__heading">
                               {title}
                           </h1>
                           {introtext && 
                               <p className="ffe-lead-paragraph sb1ds-intro__paragraph">
                                   {introtext}
                               </p>
                           }
                           {illustration &&
                               <div className="sb1ds-intro__illustration" aria-hidden="true" role="presentation">
                                   <img src={`/img/${illustration}`} alt=""/>
                               </div>
                           }
                       </div>
                       {children}
                   </main>

                   <InPageNavigation/>
               </div>
           </div>
       </>
   );
};

Layout.propTypes = {
    // page title, for headline and document title
    title: string.isRequired,
    // page content
    children: node.isRequired,
    introtext: string,
    illustration: string
};

export default Layout;
