import React from 'react';
import PropTypes from 'prop-types';
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Paragraph } from '@sb1/ffe-core-react';
import { BulletList, BulletListItem, CheckList, CheckListItem } from '@sb1/ffe-lists-react';
import DSExample from './DSExample';
import AnimationExample from './AnimationExample';
import UUTestReport from './UUTestReport';
import { IconsUseCaseList, IconsUseCaseListItem } from './IconsUseCaseList';
import { LinkWithTracking } from './LinkWithTracking';

export const textToFragment = (text, withHash = false) => `${withHash ? '#' : ''}${text.toLocaleLowerCase().replace(/ /g, '-')}`;

export const Header2WithIdTag = ({children}) => <Heading2 id={textToFragment(children)} className={'sb1ds-section-static__h2 sb1ds-header-offset'}>{children}</Heading2>;
Header2WithIdTag.propTypes = {
    children: PropTypes.node.isRequired
};

export const Heading3WithClass = ({ children }) => <Heading3 className='sb1ds-section-static__h3'>{children}</Heading3>;
Heading3WithClass.propTypes = {
    children: PropTypes.node.isRequired
};

export const scope = {
    DSExample,
    AnimationExample,
    UUTestReport,
    CheckList,
    CheckListItem
};

export default {
    h1: Heading1,
    h2: Header2WithIdTag,
    h3: Heading3WithClass,
    h4: Heading4,
    h5: Heading5,
    h6: Heading6,
    p: Paragraph,
    ul: BulletList,
    li: BulletListItem,
    a: LinkWithTracking,
    IconsUseCaseList,
    IconsUseCaseListItem,
    ...scope
};

