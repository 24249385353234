import React from 'react';
import { string, node, shape } from 'prop-types';
import { MDXProvider } from '@mdx-js/react';
import MDXShortcodes from '../mdx-shortcodes';
import Layout from './Layout';

/**
 * Default template for MDX content
 */
const MDXPage = ({pageContext: {frontmatter}, children}) => {
    return (
        <Layout title={frontmatter.title} 
                introtext={frontmatter.introtext} 
                illustration={frontmatter.illustration}>
            <MDXProvider components={MDXShortcodes}>
                {children}
            </MDXProvider>
        </Layout>
    );
};

MDXPage.propTypes = {
    pageContext: shape({
        frontmatter: shape({
            title: string.isRequired,
            introtext: string,
            illustration: string
        }).isRequired
    }).isRequired,
    children: node.isRequired
};

export default MDXPage;
