require('prismjs/themes/prism.css');
const {
    initGlobalTracker,
    AppContextEnum,
    BankEnum,
    BackendEnvironmentEnum
} = require('@sb1/web-behavior-tracker');
const {DestinationEnum} = require('@sb1/web-behavior-tracker/src/types/enums');
const React = require('react');
const MDXPage = require('./src/templates/MDXPage').default;

exports.onClientEntry = () => {
    initGlobalTracker(() => ({
        user: {
            anonymousId: '',
            sessionId: '',
            optins: {
                functional: false,
                statistics: true,
                marketing: false
            },
            language: 'nb'
        },
        destinations: [DestinationEnum.Humio],
        bank: BankEnum.All,
        application: {
            name: 'designsystem-cms',
            context: AppContextEnum.intern
        },
        disableTrackingCookies: true,
        backendEnvironment: process.env.NODE_ENV === 'development'
            ? BackendEnvironmentEnum.Test
            : BackendEnvironmentEnum.Prod
    }));
};

// Wraps every page with the MDXPage layout component
// This replaces the defaultLayouts option from gatsby-plugin-mdx in Gatsby v3
exports.wrapPageElement = ({ element, props }) => {
    // Check if this is an MDX page by examining the component name or path
    const isMDXPage = props.path && (
        props.path.endsWith('/') || // Pages with trailing slash
        props.path.includes('.mdx') || // Direct MDX references
        props.pageContext?.id?.includes('Mdx') // MDX nodes in GraphQL
    );
    
    // Apply the MDXPage layout to MDX pages
    if (isMDXPage && props.pageContext && props.pageContext.frontmatter) {
        return <MDXPage {...props}>{element}</MDXPage>;
    }
    
    return element;
};
